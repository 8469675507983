import React from 'react';

const Spinner = () => {
  return (
    <div style={{textAlign: "center"}}>
      <div className="loadingio-spinner-spinner-8xyul1xfgha">
        <div className="ldio-p66ajun1rp">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <p style={{marginBottom: "2rem"}}>Загрузка данных ...</p>
    </div>
  )
};

export default  Spinner;
