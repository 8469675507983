export const includesAny = (arr, values) => values.some(v => arr.includes(v));
export const includesAll = (arr, values) => values.every(v => arr.includes(v));

export const removeDuplicates = (originalArray, prop) => {
  let newArray = [];
  let lookupObject  = {};

  for (let i in originalArray) {
    lookupObject[originalArray[i][prop]] = originalArray[i];
  }

  for (let i in lookupObject) {
    newArray.push(lookupObject[i]);
  }
  return newArray;
};

export const APP_URL = 'https://edprogs.ncfu.ru/';

