import React from 'react';
import Fade from "react-reveal/Fade";

const ProgItem = ({prog, handleModalOpen}) => {

  return (
    <Fade key={prog.id}>

      <div className={`prog-list__item ${(prog.isHidden) ? ` prog-list__item--isHidden` : ``}` } >

        <p className='prog-list__name-container'>{prog.name}</p>

        <div className='prog-list__info-container'>
          {prog.info.map((item) => (
            <div className='info' key={item.id}>
              <div className='info__item'>
                <svg  width="20" height="20" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512 512" >
                  <g fill="#035eaf">
                    <g>
                      <g>
                        <path d="M452,40h-24V0h-40v40H124V0H84v40H60C26.916,40,0,66.916,0,100v352c0,33.084,26.916,60,60,60h392
                      c33.084,0,60-26.916,60-60V100C512,66.916,485.084,40,452,40z M472,452c0,11.028-8.972,20-20,20H60c-11.028,0-20-8.972-20-20V188
                      h432V452z M472,148H40v-48c0-11.028,8.972-20,20-20h24v40h40V80h264v40h40V80h24c11.028,0,20,8.972,20,20V148z"/>
                      </g>
                    </g>
                    <g>
                      <g>
                        <rect x="76" y="230" width="40" height="40"/>
                      </g>
                    </g>
                    <g>
                      <g>
                        <rect x="156" y="230" width="40" height="40"/>
                      </g>
                    </g>
                    <g>
                      <g>
                        <rect x="236" y="230" width="40" height="40"/>
                      </g>
                    </g>
                    <g>
                      <g>
                        <rect x="316" y="230" width="40" height="40"/>
                      </g>
                    </g>
                    <g>
                      <g>
                        <rect x="396" y="230" width="40" height="40"/>
                      </g>
                    </g>
                    <g>
                      <g>
                        <rect x="76" y="310" width="40" height="40"/>
                      </g>
                    </g>
                    <g>
                      <g>
                        <rect x="156" y="310" width="40" height="40"/>
                      </g>
                    </g>
                    <g>
                      <g>
                        <rect x="236" y="310" width="40" height="40"/>
                      </g>
                    </g>
                    <g>
                      <g>
                        <rect x="316" y="310" width="40" height="40"/>
                      </g>
                    </g>
                    <g>
                      <g>
                        <rect x="76" y="390" width="40" height="40"/>
                      </g>
                    </g>
                    <g>
                      <g>
                        <rect x="156" y="390" width="40" height="40"/>
                      </g>
                    </g>
                    <g>
                      <g>
                        <rect x="236" y="390" width="40" height="40"/>
                      </g>
                    </g>
                    <g>
                      <g>
                        <rect x="316" y="390" width="40" height="40"/>
                      </g>
                    </g>
                    <g>
                      <g>
                        <rect x="396" y="310" width="40" height="40"/>
                      </g>
                    </g>
                  </g>
                </svg>
                <p className='info__value'>{item.term}
                  <span>{(item.term === '5' || item.term === '5,5' || item.term === '6') ? ` лет` : ` года`}</span></p>
                <p className='info__text'>{item.form}</p>
              </div>
              <div className='info__item'>
                <svg height="20"  width="20"  viewBox="0 0 511.449 511.449"
                     xmlns="http://www.w3.org/2000/svg">
                  <path fill="#5EC53E"
                    d="m436.508 74.941c-99.913-99.913-261.639-99.928-361.566 0-99.914 99.912-99.93 261.64 0 361.567 99.913 99.913 261.639 99.928 361.566 0 99.913-99.912 99.929-261.64 0-361.567zm-180.784 394.45c-117.816 0-213.667-95.851-213.667-213.667s95.851-213.666 213.667-213.666 213.667 95.851 213.667 213.667-95.85 213.666-213.667 213.666z"/>
                  <path fill="#5EC53E"
                    d="m287.724 128.058h-74.667c-11.598 0-21 9.402-21 21v85.667h-21.666c-11.598 0-21 9.402-21 21s9.402 21 21 21h21.666v22h-21.666c-11.598 0-21 9.402-21 21s9.402 21 21 21h21.666v21.667c0 11.598 9.402 21 21 21s21-9.402 21-21v-21.667h43c11.598 0 21-9.402 21-21s-9.402-21-21-21h-43v-22h53.667c40.987 0 74.333-33.346 74.333-74.333 0-40.988-33.345-74.334-74.333-74.334zm0 106.666h-53.667v-64.667h53.667c17.828 0 32.333 14.504 32.333 32.333s-14.504 32.334-32.333 32.334z"/>
                </svg>
                <p className='info__value'>
                  {item.budgetAmount}
                </p>
                <p className='info__text'>Бюджетных мест</p>
              </div>
              <div className='info__item'>
                <svg height="20"  width="20"  viewBox="0 0 511.449 511.449"
                     xmlns="http://www.w3.org/2000/svg">
                  <path fill="#035eaf"
                        d="m436.508 74.941c-99.913-99.913-261.639-99.928-361.566 0-99.914 99.912-99.93 261.64 0 361.567 99.913 99.913 261.639 99.928 361.566 0 99.913-99.912 99.929-261.64 0-361.567zm-180.784 394.45c-117.816 0-213.667-95.851-213.667-213.667s95.851-213.666 213.667-213.666 213.667 95.851 213.667 213.667-95.85 213.666-213.667 213.666z"/>
                  <path fill="#035eaf"
                        d="m287.724 128.058h-74.667c-11.598 0-21 9.402-21 21v85.667h-21.666c-11.598 0-21 9.402-21 21s9.402 21 21 21h21.666v22h-21.666c-11.598 0-21 9.402-21 21s9.402 21 21 21h21.666v21.667c0 11.598 9.402 21 21 21s21-9.402 21-21v-21.667h43c11.598 0 21-9.402 21-21s-9.402-21-21-21h-43v-22h53.667c40.987 0 74.333-33.346 74.333-74.333 0-40.988-33.345-74.334-74.333-74.334zm0 106.666h-53.667v-64.667h53.667c17.828 0 32.333 14.504 32.333 32.333s-14.504 32.334-32.333 32.334z"/>
                </svg>
                <p className='info__value'>{item.paidAmount}</p>
                <p className='info__text'>Платных мест</p>
              </div>
              <div className='info__item'>
                <svg height="20" width="20" viewBox="0 0 507.246 507.246"
                     xmlns="http://www.w3.org/2000/svg">
                  <g fill="#035eaf">
                    <path
                      d="m457.262 89.821c-2.734-35.285-32.298-63.165-68.271-63.165h-320.491c-37.771 0-68.5 30.729-68.5 68.5v316.934c0 37.771 30.729 68.5 68.5 68.5h370.247c37.771 0 68.5-30.729 68.5-68.5v-256.333c-.001-31.354-21.184-57.836-49.985-65.936zm-388.762-31.165h320.492c17.414 0 32.008 12.261 35.629 28.602h-356.121c-13.411 0-25.924 3.889-36.5 10.577v-2.679c0-20.126 16.374-36.5 36.5-36.5zm370.246 389.934h-370.246c-20.126 0-36.5-16.374-36.5-36.5v-256.333c0-20.126 16.374-36.5 36.5-36.5h370.247c20.126 0 36.5 16.374 36.5 36.5v55.838h-102.026c-40.43 0-73.322 32.893-73.322 73.323s32.893 73.323 73.322 73.323h102.025v53.849c0 20.126-16.374 36.5-36.5 36.5zm36.5-122.349h-102.025c-22.785 0-41.322-18.537-41.322-41.323s18.537-41.323 41.322-41.323h102.025z"/>
                    <circle cx="379.16" cy="286.132" r="16.658"/>
                  </g>
                </svg>
                <p className='info__value'>{item.price} <span>тыс. руб</span></p>
                <p className='info__text'>Стоимость</p>
              </div>
            </div>
          ))}
        </div>

        <div className='prog-list__btn-container'>
          <button className='button'
                  onClick={() => handleModalOpen(prog.id)}> Подробнее
          </button>
        </div>
      </div>
    </Fade>
  )
};

export default ProgItem;
