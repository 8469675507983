import React from 'react';
import ProgItem from "./ProgItem.jsx";

const ProgList = ({progs, handleModalOpen} ) => {

  return (
    <>
      {(progs.length > 0) &&
      <>
        <div className='prog-list'>
          {progs.map(prog => <ProgItem key={prog.id} prog={prog} handleModalOpen={handleModalOpen}/>)}
        </div>
      </>
      }
    </>
  )
};

export default ProgList;
