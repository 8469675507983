import React, {useEffect, useState} from "react";
import useAxios from 'axios-hooks'
import {APP_URL,  removeDuplicates} from "./helper";

import ProgList from "./components/ProgList.jsx";
import ReactModal from "react-modal";
import ProgDetail from "./components/ProgDetail.jsx";
import Spinner from "./components/Spinner.jsx";

const Main = () => {
  const MAX_PROGS = 4;
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedProgId, setSelectedProgId] = useState();
  const [activeTabId, setActiveTabId] = useState(1);
  const [filteredProgs, setFilteredProgs] = useState([]);
  const [tabs, setTabs] = useState([]);
  const [btnShowAllClicked, setBtnShowAllClicked] = useState(false);

  // id для загрузки инфы по институту берём из разметки
  // должен быть div с аттрибутом data-institute, где необходимо указать id институа в бд
  let instId = 1;
  const instIdEl = document.querySelector('div[data-institute]');
  if (instIdEl) {
    instId =  instIdEl.dataset.institute
  } else  {
    console.log('Отсутствует div с аттрибутом data-institute, где необходимо указать id институа в бд. Заргужены специальности первого института ');
  }

  const [{ data, loading, error }] = useAxios(
    APP_URL +'api/list-inst/' + instId
  );

  useEffect(() => {
    if (data) {
      const allProgs = data.data;
      const uniqueProgs = removeDuplicates(allProgs, "type");

      const allTypes = uniqueProgs.map((prog, i) => {
        return {id: i, name: prog.type}
      });
      setTabs(allTypes);
      onTabClick(allTypes[0]);
    }
  }, [data]);

  if (loading) return  <Spinner/>;

  if (error) return <p>Ошибка загрузки. {error}</p>;

  const onTabClick = (tab) => {
    const progs = data.data;
    let isHidden;
    let progsToShow = progs
      .filter(prog => prog.type === tab.name)
      .map((prog, index) => {
        isHidden = !(index < MAX_PROGS);
        return {...prog,
          isHidden: isHidden
        }
      });

    // если программ больше восьми, то нужно поменять надпись на кнопке при клики на таб
    if (!isHidden) setBtnShowAllClicked(false);

    setActiveTabId(tab.id);
    setFilteredProgs(progsToShow);
  };

  const onShowAllClick = () => {
    let progs;
    if (!btnShowAllClicked) {
      progs = filteredProgs.map(prog => ({
        ...prog,
        isHidden: false
      }));
    } else {
      progs = filteredProgs.map((prog, index) => ({
        ...prog,
        isHidden: !(index < MAX_PROGS)
      }));
    }

    setBtnShowAllClicked(prevStatus => !prevStatus);

    setFilteredProgs(progs);
  };

  const handleModalOpen = (progId) => {
    setSelectedProgId(progId);
    setModalIsOpen(true);
  };

  const handleModalClose = () => {
    setModalIsOpen(false);
    document.body.removeAttribute('style');

    const professionsSliderLanding = document.querySelector('._professions-slider');
    const headerLanding = document.querySelector('header');
    if (professionsSliderLanding !== null && headerLanding !==null) {
      professionsSliderLanding.style.zIndex = '10';
      headerLanding.style.zIndex = '10';
    }

    const searchInputMain = document.querySelector('.header__search-from input'); //1
    const searchButtonMain = document.querySelector('.header__search-from button'); //2
    const menuMain = document.querySelector('.menu'); //1
    if (searchInputMain !== null && searchButtonMain !==null && menuMain !==null) {
      searchInputMain.style.zIndex = '1';
      searchButtonMain.style.zIndex = '2';
      menuMain.style.zIndex = '1';
    }
  };

  const onAfterModalOpen = () => {
    document.body.style.overflow = 'hidden';

    const professionsSliderLanding = document.querySelector('._professions-slider');
    const headerLanding = document.querySelector('header');
    if (professionsSliderLanding !== null && headerLanding !==null) {
      professionsSliderLanding.style.zIndex = '-1';
      headerLanding.style.zIndex = '-1';
    }

    const searchInputMain = document.querySelector('.header__search-from input'); //1
    const searchButtonMain = document.querySelector('.header__search-from button'); //2
    const menuMain = document.querySelector('.menu'); //1
    if (searchInputMain !== null && searchButtonMain !==null && menuMain !==null) {
      searchInputMain.style.zIndex = '-1';
      searchButtonMain.style.zIndex = '-2';
      menuMain.style.zIndex = '-1';
    }
  };

  return (
    <div className='_block01'>
      <div className='tabs'>
        {tabs.map((tab) => (
          <button key={tab.id} onClick={() => onTabClick(tab)}
                  className={`_btn-lnk tabs__btn ${tab.id === activeTabId ? `tabs__btn--active`: ``}`} role="button"
                  type="button">
            {tab.name}
          </button>
        ))}
      </div>

      <div>
        <ProgList progs={filteredProgs} handleModalOpen={handleModalOpen}/>
        {filteredProgs.length > MAX_PROGS &&
        <div className="text-center mt-4">
          <button className="button" onClick={onShowAllClick}>{!(btnShowAllClicked) ? `Показать все` : `Скрыть` }</button>
        </div>}
      </div>

      <ReactModal
        isOpen={modalIsOpen}
        contentLabel="onRequestClose Example"
        onAfterOpen={onAfterModalOpen}
        onRequestClose={handleModalClose}
        ariaHideApp={false}
        portalClassName="detail__modal"
        closeTimeoutMS={500}
        style={
          {
            overlay: {backgroundColor: "rgba(27, 26, 26, 0.8)"},
          }
        }
      >
        <button className='btn-close' onClick={handleModalClose}>&times;</button>
        <ProgDetail selectedProgId = {selectedProgId}/>
      </ReactModal>
    </div>
  )
};

export default Main;
